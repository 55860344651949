import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BreadCrumbsComponent } from './bread-crumbs.component';
import { RouterLinkWithHref } from "@angular/router";


@NgModule({
  declarations: [
    BreadCrumbsComponent,
  ],
  imports: [
    CommonModule,
    RouterLinkWithHref,
  ],
  exports: [
    BreadCrumbsComponent,
  ],
})
export class BreadCrumbsModule { }
