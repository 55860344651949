import { createFeatureSelector, createSelector } from "@ngrx/store";

import { TUserState, TUSER_FEATURE_KEY } from './tuser.state';

/**
 * Селектор данных хранилища NGRX - Состояние.
 */
export const getTUserState = createFeatureSelector<TUserState>(TUSER_FEATURE_KEY);

/**
 * Селектор данных хранилища NGRX - Получение данных из хранилища.
 */
export const getTUserLoaded = createSelector(
  getTUserState,
  (state: TUserState) => state.tUser
);
