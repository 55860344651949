import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from "../../_pipes";
import { ButtonSignoutComponent } from "./button-signout.component";
import { SpriteIconModule } from "../sprite-icon";


@NgModule({
  declarations: [
    ButtonSignoutComponent,
  ],
    imports: [
        CommonModule,
        PipesModule,
        SpriteIconModule,
    ],
  exports: [
    ButtonSignoutComponent,
  ]
})
export class ButtonSignoutModule {
}
