import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TelegramAuthComponent } from './telegram-auth.component';


@NgModule({
  declarations: [
    TelegramAuthComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TelegramAuthComponent,
  ]
})
export class TelegramAuthModule { }
