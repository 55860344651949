import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterComponent } from './footer.component';
import { SpriteWithHoverModule } from "../sprite-with-hover";
import { PipesModule } from "../../_pipes";


@NgModule({
  declarations: [
    FooterComponent,
  ],
    imports: [
        CommonModule,
        SpriteWithHoverModule,
        PipesModule,
    ],
  exports: [
    FooterComponent,
  ],
})
export class FooterModule { }
