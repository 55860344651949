import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpriteIconModule } from "../sprite-icon";
import { TableResizeModule } from "../../_directives";

import { TableComponent } from './table.component';


@NgModule({
  declarations: [
    TableComponent,
  ],
  imports: [
    CommonModule,
    TableResizeModule,
    SpriteIconModule,
  ],
  exports: [
    TableComponent,
  ],
})
export class TableModule { }
