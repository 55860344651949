import { NgModule } from '@angular/core';
import { EventType, NavigationEnd, Router, RouterModule, Routes } from '@angular/router';

import { AuthServiceConfig } from "./_services";

/**
 * Описание роутинга.
 * Модули подключаются используя ленивую инициализацию, компилятор Angular, в таком режиме, для каждого модуля
 * создаёт отдельный файл, что ускоряет первоначальную загрузку и запуск приложения.
 */
const routes: Routes = [
  {
    path: 'index',
    loadChildren: () => import('./index/index.module').then((m) => m.IndexModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },

  {path: '**', redirectTo: 'index'},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  /**
   * Конструктор.
   * @param router - Роутер приложения.
   * @param config - Конфигурация сервиса аутентификации.
   */
  constructor(
    private router: Router,
    public config: AuthServiceConfig,
  ) {
    // Подписка на события переключения роутинга для отслеживания недопустимых URN, которые иногда проскакивают.
    this.router.events.subscribe((e2:any)=>{
      if (Object.prototype.hasOwnProperty.call(e2, "type")) {
        const event: NavigationEnd = e2 as NavigationEnd;
        if (event.type === EventType.NavigationEnd) {
          this.ifRootRedirectToDefaultUrn(event);
        }
      }
    });
  }

  /**
   * Редирект на основной URN для авторизированных пользователей если текущий финальный URN равен '/'.
   * @param ne - Объект события навигации с типом NavigationEnd.
   */
  private ifRootRedirectToDefaultUrn(ne: NavigationEnd):void {
    // if (ne.urlAfterRedirects === "/") {
    //   this.router.navigateByUrl(this.config.defaultAuthorizedUrn).then((_:boolean) => {
    //   });
    // }
  }
}
