import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpriteIconModule } from "../sprite-icon";
import { InputButtonRegularComponent } from './input-button-regular.component';


@NgModule({
  declarations: [
    InputButtonRegularComponent,
  ],
  imports: [
    CommonModule,
    SpriteIconModule,
  ],
  exports: [
    InputButtonRegularComponent,
  ],
})
export class InputButtonRegularModule { }
