/**
 * Конфигурация AuthService.
 */
export class AuthServiceConfig {
  /** Интервал проверки сессии аутентификации в миллисекундах. Если указано 0 - проверка отключается. */
  authCheckTimeout!: number;

  /** URN по умолчанию для авторизованных пользователей. */
  defaultAuthorizedUrn!: string;

  /** URN по умолчанию для неавторизованных пользователей. */
  defaultUnauthorizedUrn!: string;

  /**
   * URN допустимые для доступа не авторизованным пользователям, если при открытии страницы указан URN.
   * Если URN не указан, тогда происходит редирект на defaultUnauthorizedUrn.
   */
  unauthorizedUrnList!: string[];

  /**
   * URN допустимые для доступа авторизованным пользователям, если при открытии страницы указан URN.
   * Если URN не указан, тогда происходит редирект на defaultAuthorizedUrn.
   */
  authorizedUrnList!: string[];
}

/**
 * Структура сессии авторизации.
 */
export interface IAuthSession {
  /**
   * Сессионный токен доступа к методам API (при использовании httpOnly cookie не используется).
   */
  token?: string;

  /**
   * Дата и время создания сессии.
   */
  createAt: string | Date | undefined;

  /**
   * Дата и время, после которой сессия авторизации удаляется.
   */
  expiresAt: string | Date | undefined;

  /**
   * Оставшееся время жизни сессии в секундах. expiresAt = createdAt + lifetime.
   */
  lifetime: number;
}
