import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpriteIconModule } from "../sprite-icon";
import { SpriteWithHoverComponent } from './sprite-with-hover.component';


@NgModule({
  declarations: [
    SpriteWithHoverComponent,
  ],
  imports: [
    CommonModule,
    SpriteIconModule,
  ],
  exports: [
    SpriteWithHoverComponent,
  ],
})
export class SpriteWithHoverModule { }
