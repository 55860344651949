import { AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';


const spriteIconSvg = 'assets/images/component-sprite-icon.svg';
const defaultWidth: number = 24;
const defaultHeight: number = 24;

/**
 * Отображение одной иконки с заданными параметрами.
 * Иконка берётся из единого спрайта иконок и обрезается до меньших размеров, если требуется.
 * @param name   - Название иконки в спрайте.
 * @param width  - Ширина иконки. Указывается только если иконка меньше 24px,
 *                 тогда она будет обрезана по указанному значению.
 * @param height - Высота иконки. Указывается только если иконка меньше 24px,
 *                 тогда она будет обрезана по указанному значению.
 *
 * Пример использования:
 * @example
 * <component-sprite-icon
 *    [name]="'eye-close-active'"
 *    [width]="16"
 *    [height]="12"
 * ></component-sprite-icon>
 */
@Component({
  selector: 'component-sprite-icon',
  templateUrl: './sprite-icon.component.html',
  styleUrls: ['./sprite-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpriteIconComponent implements AfterViewInit {
  @Input() name: string = '';
  @Input() width: number = defaultWidth;
  @Input() height: number = defaultHeight;
  @ViewChild('svg') svg!: ElementRef<SVGElement>;

  /**
   * Конструктор.
   */
  constructor() {
  }

  /** Событие завершения отображения элементов HTML страницы. */
  ngAfterViewInit(): void {
    this.init();
  }

  /** Инициализация SVG иконки, установка размера, установка переданной иконки по имени. */
  protected init(): void {
    const attributes: NamedNodeMap = this.svg.nativeElement.attributes;
    let attr: Attr | null;

    // viewBox
    attr = this.setAttr(attributes, 'viewBox', '0 0 ' + this.width.toString() + ' ' + this.height.toString());
    this.svg.nativeElement.attributes.setNamedItem(attr!);
    // width
    attr = this.setAttr(attributes, 'width', this.width.toString() + 'px');
    this.svg.nativeElement.attributes.setNamedItem(attr!);
    // height
    attr = this.setAttr(attributes, 'height', this.height.toString() + 'px');
    this.svg.nativeElement.attributes.setNamedItem(attr!);
    // Изменение ссылки на SVG спрайт.
    this.svg.nativeElement.childNodes.forEach((value: ChildNode) => {
      const svgUse = value as SVGUseElement;
      attr = this.setAttr(svgUse.attributes, 'xlink:href', spriteIconSvg + '#' + this.name);
      svgUse.attributes.setNamedItem(attr!);
    });
  }

  /**
   * Изменение атрибута HTML элемента.
   *
   * @param attributes - Карта аттрибутов объекта.
   * @param name       - Название атрибута.
   * @param value      - Значение атрибута.
   */
  private setAttr(attributes: NamedNodeMap, name: string, value: string): Attr | null {
    let ret: Attr | null;

    ret = attributes.getNamedItem(name);
    if (ret && ret.name === name) {
      ret.value = value;
    }

    return ret;
  }
}
