import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuSectionComponent } from './menu-section.component';
import { RouterLink, RouterLinkActive } from "@angular/router";


@NgModule({
  declarations: [
    MenuSectionComponent,
  ],
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
  ],
  exports: [
    MenuSectionComponent,
  ],
})
export class MenuSectionModule { }
