import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpriteIconModule } from "../sprite-icon";
import { InputButtonFilterComponent } from './input-button-filter.component';


@NgModule({
  declarations: [
    InputButtonFilterComponent,
  ],
  imports: [
    CommonModule,
    SpriteIconModule,
  ],
  exports: [
    InputButtonFilterComponent,
  ],
})
export class InputButtonFilterModule { }
