import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";

import { SpriteIconModule } from "../sprite-icon";
import { InputSmallNumberComponent } from './input-small-number.component';


@NgModule({
  declarations: [
    InputSmallNumberComponent,
  ],
  imports: [
    CommonModule,
    SpriteIconModule,
    FormsModule,
  ],
  exports: [
    InputSmallNumberComponent,
  ]
})
export class InputSmallNumberModule { }
