<table
  *ngIf="data.length > 0"
  tableResize
  [columnWidth]="columnWidth"
  [busy]="resizeBusySubject"
  [disabled]="false"
  (outColumnWidthResponse)="onColumnResize($event)"
  class="border-collapse border border-bbGrayTableBorder border-r-0 w-full whitespace-nowrap">
  <thead class="bg-bbGrayTableHeader select-none h-[24px]">
  <tr [ngClass]="{'blur-sm': busy}">
    <th *ngFor="let item of head; index as n"
        #displayExpectationsHead
        class="flex-nowrap border border-bbGrayTableBorder break-all -mr-1">
      <div
        [ngClass]="{
        'cursor-pointer': item.sort && !busy,
        'pr-[16px]': item.sort}"
        (click)="onClickHead($event, n)"
        class="px-[8px] text-start flex justify-between"
      >
        <div
          [ngClass]="item.class?item.class:''"
          class="w-full">{{item.name}}</div>
        <component-sprite-icon
          *ngIf="item.icon"
          [name]="item.icon"
          class="pl-[8px]"></component-sprite-icon>
        <component-sprite-icon
          *ngIf="item.sort==='none'" [name]="'sort-16px-active'" [width]="10" [height]="16"
          class="absolute right-[2px] top-[50%] -mt-[8px]"
        ></component-sprite-icon>
        <component-sprite-icon
          *ngIf="item.sort==='asc'" [name]="'sort-16px-asc'" [width]="10" [height]="16"
          class="absolute right-[2px] top-[50%] -mt-[8px]"></component-sprite-icon>
        <component-sprite-icon
          *ngIf="item.sort==='desc'" [name]="'sort-16px-desc'" [width]="10" [height]="16"
          class="absolute right-[2px] top-[50%] -mt-[8px]"></component-sprite-icon>
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <!-- Данные. -->
  <tr
    #displayExpectationsData
    *ngFor="let row of data; index as n"
    [ngClass]="{'blur-sm': busy,'hover:bg-bbGrayTableSelected': !busy}"
    class="h-[24px]"
  >
    <td
      *ngFor="let col of row; index as i"
      (click)="onClickBody($event, col.id, n, i)"
      [ngClass]="{'cursor-pointer':!noClickable}"
      class="border border-bbGrayTableBorder whitespace-normal px-[8px] break-all select-none"
    >{{col.data}}</td>
  </tr>
  <!-- /Данные. -->
  </tbody>
</table>

<!-- Отсутствие данных. -->
<div
  #displayExpectationsEmpty
  *ngIf="data.length === 0"
  [ngClass]="{'blur-sm': busy}"
  class="p-[20px] text-center text-[14px] break-all border border-bbGrayTableBorder">Данные отсутствуют.
</div>
<!-- /Отсутствие данных. -->
