import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TablePaginatorComponent } from './table-paginator.component';
import { InputSmallNumberModule } from "../input-small-number";
import { SpriteIconModule } from "../sprite-icon";


@NgModule({
  declarations: [
    TablePaginatorComponent,
  ],
  imports: [
    CommonModule,
    InputSmallNumberModule,
    SpriteIconModule,
  ],
  exports: [
    TablePaginatorComponent,
  ],
})
export class TablePaginatorModule { }
