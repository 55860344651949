import { createAction, props } from '@ngrx/store';

import { ISessionCreateResponse } from "../_services";

/**
 * Описание событий редуктора данных.
 */
export enum TUserActionsNames {
  Update = '[TUser] Update',
  Reset = '[TUser] Reset',
}

/**
 * Событие обновления данных в хранилище NGRX.
 */
export const Update = createAction(
  TUserActionsNames.Update,
  props<{ data: ISessionCreateResponse }>(),
);

/**
 * Событие сброса данных в хранилище NGRX.
 */
export const Reset = createAction(TUserActionsNames.Reset);
