import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpriteIconModule } from "../sprite-icon";
import { InputButtonSelectComponent } from './input-button-select.component';


@NgModule({
  declarations: [
    InputButtonSelectComponent,
  ],
  imports: [
    CommonModule,
    SpriteIconModule,
  ],
  exports: [
    InputButtonSelectComponent,
  ],
})
export class InputButtonSelectModule { }
