import { Action, createReducer, on } from '@ngrx/store';
import { initialTUserState, TUserState } from "./tuser.state";
import * as tuserActions from './tuser.actions';

/**
 * Описание редуктора и всех событий редуктора данных NGRX.
 */
export const tuserReducer = createReducer(
  initialTUserState,
  /* Событие обновления данных в хранилище. */
  on(tuserActions.Update, (state, {data}) => {
    return {
      ...state,
      tUser: data,
    };
  }),
  /* Событие сброса данных в хранилище. */
  on(tuserActions.Reset, (state) => ({
    ...state,
    tUser: undefined,
  }))
);

/**
 * Экспорт редуктора данных tuserReducer.
 * @param state  - Данные.
 * @param action - Событие.
 */
export function reducer(state: TUserState | undefined, action: Action) {
  return tuserReducer(state, action);
}
