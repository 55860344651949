import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpriteIconComponent } from './sprite-icon.component';


@NgModule({
  declarations: [
    SpriteIconComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SpriteIconComponent,
  ],
})
export class SpriteIconModule { }
