import { NgModule } from '@angular/core';

import { LocalizedDatePipe } from './localized-date.pipe';
import { MinutesPipe } from "./minutes.pipe";
import { HoursPipe } from "./hours.pipe";
import { RublesPipe } from "./rub.pipe";


@NgModule({
  declarations: [
    LocalizedDatePipe,
    MinutesPipe,
    HoursPipe,
    RublesPipe,
  ],
  exports: [
    LocalizedDatePipe,
    MinutesPipe,
    HoursPipe,
    RublesPipe,
  ]
})
export class PipesModule {
}
