import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FieldsetSwitchComponent } from './fieldset-switch.component';
import { ReactiveFormsModule } from "@angular/forms";
import { SpriteIconModule } from "../sprite-icon";


@NgModule({
  declarations: [
    FieldsetSwitchComponent,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SpriteIconModule,
    ],
  exports: [
    FieldsetSwitchComponent,
  ],
})
export class FieldsetSwitchModule { }
